import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import BlogList from "./BlogList";
const pluralize = require("pluralize");

class RecentUpdatesBlogPagesTemplate extends React.Component {
  render() {
    let { data, renderMode, renderSize, renderWeight } = this.props;
    let { group } = data.allMarkdownRemark;

    // use group to prevent duplicate entries to same slug

    //console.log(group);
    let posts = [];
    group.forEach((item) => {
      posts.push(item.edges[0]);
    });

    posts.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.node.parent.modifiedTime) -
        new Date(a.node.parent.modifiedTime)
      );
    });

    let { authors } = data;
    let { header } = data;

    if (header.frontmatter) header = header.frontmatter;
    if (!renderSize) renderSize = 4;
    if (!renderWeight) renderWeight = "normal";
    if (!renderMode) renderMode = "";

    if (header && header.layout && header.layout.render) {
      if (header.layout.render.recent) {
        renderMode = header.layout.render.recent;
      }
      if (header.layout.render.size) {
        renderSize = header.layout.render.size;
      }
      if (header.layout.render.weight) {
        renderWeight = header.layout.render.weight;
      }
    }

    // limit to max of 10 results

    posts = posts.slice(0, 10);
    authors = authors.nodes;

    return (
      <BlogList
        posts={posts}
        authors={authors}
        emptyMessage="We don't have any recently updated pages yet."
        titleMode="h3.5"
        title={`Recently Updated Blog ${pluralize("Post", posts.length)}:`}
        titleClassName="recent-updates"
        renderMode={renderMode}
        renderSize={renderSize}
        renderWeight={renderWeight}
      />
    );
  }
}

RecentUpdatesBlogPages.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  slug: PropTypes.string,
};

export default function RecentUpdatesBlogPages(args) {
  return (
    <StaticQuery
      query={graphql`
        query RecentUpdatesBlogPagesPageComponent {
          authors: allMarkdownRemark(
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
          header: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              layout {
                columns
                render {
                  size
                  recent
                  position
                  weight
                }
              }
              breadcrumbs {
                positionClass
                separatorClass
                position
                showDate
                showCurrent
              }
            }
          }
          allMarkdownRemark(
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "blog-post" } } }
            }
          ) {
            group(field: fields___slug) {
              edges {
                node {
                  frontmatter {
                    title
                    titles {
                      subtitle
                      footer
                    }
                    category
                    author
                    creationDate
                    summary
                    tags
                    images {
                      hero {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 100
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                  fields {
                    slug
                  }
                  parent {
                    ... on File {
                      modifiedTime
                      birthTime
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <RecentUpdatesBlogPagesTemplate
          data={data}
          count={count}
          slug={args.slug}
          renderMode={args.renderMode}
          renderSize={args.renderSize}
          renderWeight={args.renderWeight}
        />
      )}
    />
  );
}
